import { useLogger } from '@tumelo/logging'
import { useAppDispatch, useAppSelector } from '../store'
import { logOut } from '../features/auth/asyncActions'
import { selectFidelityLogout } from '../features/config/selectors'
import { getQueryVariable } from '../../utils/getQueryVariable'
import { useRouter } from '../router/useRouter'

/**
 * logout(withSingleLogout = true)
 * withSingleLogout:
 *  - defaults to true, will allow a single logout endpoint to be called if one is configured
 * */
export const useLogout = () => {
  const dispatch = useAppDispatch()
  const logger = useLogger()
  const { navigate } = useRouter()
  const { enableSingleLogout, singleLogoutUrl } = useAppSelector(selectFidelityLogout)
  const startSignIn = getQueryVariable('startSignIn')

  return {
    logout: async ({ withSingleLogout } = { withSingleLogout: true }) => {
      if (enableSingleLogout && withSingleLogout) {
        try {
          const resp = await fetch(singleLogoutUrl, { method: 'GET' })
          if (resp.status !== 200) {
            logger.logError(resp.body)
          }
        } catch (e) {
          logger.logError(e)
        }
      }

      dispatch(
        logOut(() => {
          navigate({ pathname: '/', ...(startSignIn ? { query: { startSignIn } } : {}) })
        })
      )
    },
  }
}
