/* eslint-disable @typescript-eslint/no-explicit-any */
import { hash, Map as ImmMap, Record, Set as ImmSet } from 'immutable'
import { isAfter } from 'date-fns'
import {
  isPollOpen,
  Poll,
  PollId,
  PollTag,
  Tag,
  OrganizationBreakdown,
  Ballot,
  Organization,
  timestampToDate,
} from '@tumelo/shared'
import { EntityState } from '@reduxjs/toolkit'
import { Selector } from '../../utils/redux'
import { asValueObject, BallotWithPollAndOrg } from '../types/PollWithOrganization/PollWithOrganization'
import { RootState } from '../rootReducer'
import { getInitialState as PollsInitialState } from '../features/polls'
import { SearchFilter } from '../types/SearchFilters'
import { compose, pipe } from '../../utils/functional/common'
import { isErrorStates, isIntermediaryState, isValueState, Payload, tryUnwrapPayloads } from '../payload'
import { Maybe } from '../../utils/functional/maybe'
import { ballotsInitialStateFn } from '../features/ballots'
import { choose } from '../../utils/functional/arr'
import { makeMemo } from '../../utils/functional/memoize'
import { selectInvestorStatistics } from '../features/statistics/selectors'

const allNonSuccessConditionsAggregated = (state: RootState) =>
  pipe(tryUnwrapPayloads(state.polls.pollsStatus, state.ballots), (payloads) =>
    isErrorStates(payloads) || isIntermediaryState(payloads) ? payloads : undefined
  )

const errorPendingOrData =
  <T>(selector: (s: RootState) => T) =>
  (state: RootState): Payload<T> =>
    pipe(state, allNonSuccessConditionsAggregated, Maybe.defaultValueI(selector(state)))

export const selectBallotByPollId = (pollId: PollId): Selector<Payload<Ballot | null>> =>
  compose(
    (state: RootState) => state.investorVotes,
    Payload.map((b) => {
      const ballots = b.filter((ballots) => {
        return ballots.ballot?.pollId === pollId
      })
      if (ballots.length === 0) return null
      const { ballot } = ballots[0]
      if (ballot === null || ballot === undefined) return null
      return ballot
    })
  )

/* List PollWithOrganizationAndResolution */

const selectOrganization = (organizationId: string): Selector<Organization | undefined | null> =>
  compose(
    (state: RootState) => state.organizations.organizations,
    Payload.map((o) => o.entities[organizationId]),
    Payload.toMaybe
  )

const selectBallotDrivenList = (state: RootState): BallotWithPollAndOrg[] => {
  const getPoll = (pollId: PollId) =>
    pipe(
      state.polls,
      Payload.map((o) => (o.polls as EntityState<Poll, string>).entities[pollId]),
      Payload.toMaybe
    )

  return pipe(
    state.ballots,
    Payload.default(ballotsInitialStateFn()),
    (b) => Object.entries<Ballot>(b.ballots.entities),
    choose(([, ballot]) => {
      if (ballot == null) return null

      const poll = getPoll(ballot.pollId)
      if (poll == null) return null

      const organization = selectOrganization(poll.relationships.generalMeeting.organizationId)(state)
      if (organization == null) return null

      return { ballot, poll, organization }
    })
  )
}

export const selectPollDrivenList = (state: RootState): BallotWithPollAndOrg[] => {
  if (!isValueState(state.polls)) {
    return []
  }
  const polls = Object.values(state.polls.polls.entities).filter((poll): poll is Poll => !!poll)
  return polls
    .map((poll) => {
      const organization = selectOrganization(poll.relationships.generalMeeting.organizationId)(state)
      if (organization == null) return null
      return { poll, organization }
    })
    .filter((p): p is BallotWithPollAndOrg => !!p)
}

const selectOrganizationBreakdownSet: (state: RootState) => Set<string> | undefined = compose(
  (state: RootState) => state.breakdown,
  Payload.map((b: OrganizationBreakdown) => {
    const orgSet = new Set<string>()
    b.organizationEntries.forEach((org) => {
      orgSet.add(org.organization.id)
    })
    return orgSet
  }),
  Payload.toMaybe,
  (orgBreakdownSet) => orgBreakdownSet ?? undefined
)

// Select composite of ballot driven list and polls in a users org breakdown
const selectCompositeList = (state: RootState): BallotWithPollAndOrg[] => {
  const ballots = ImmSet(
    selectBallotDrivenListMemo(state)
      // we need to nullify ballots to make the items in the collection equivalent, so they are structurally equitable
      .map(asValueObject)
  )
  const polls = ImmSet(selectPollDrivenList(state).map(asValueObject))
  const pollsInPortfolio = polls.filter((b) => fixedFilters.isInMyPortfolio(state)(b))
  return ballots.union(pollsInPortfolio.subtract(ballots)).toArray()
}

export const selectBallotPrimitiveHashFn: (state: RootState) => number = compose(
  (state: RootState) => state.ballots,
  Payload.default(ballotsInitialStateFn()),
  (ballots) =>
    Object.values<Ballot>(ballots.ballots.entities).reduce(
      (acc: number, ballot: Ballot) =>
        // eslint-disable-next-line no-bitwise
        (acc + hash(ballot?.id)) ^
        (ballot?.investorVote?.response === 'for' ? 2 : ballot?.investorVote?.response === 'against' ? 1 : 0),
      0
    )
)
export const selectPollPrimitiveHashFn: (state: RootState) => number = compose(
  (state: RootState) => state.polls,
  Payload.default(PollsInitialState()),
  (b) => hash(b.polls.ids)
)
const selectCompaniesPrimitiveHashFn: (state: RootState) => number = compose(
  (state: RootState) => state.organizations.organizations,
  Payload.toMaybe,
  (b) => hash(b?.ids)
)
const selectBreakdownPrimitiveHashFn: (state: RootState) => number = compose(
  (state: RootState) => state.breakdown,
  Payload.toMaybe,
  (b) => hash(b?.organizationEntries)
)

const composeXorResults =
  <T>(...fns: ((x: T) => number)[]) =>
  (x: T) =>
    // eslint-disable-next-line no-bitwise
    fns.reduce((acc, item) => acc ^ item(x), 0)

// Identical signature to selectCompositeList, just memoizes result for performance reasons
export const selectBallotDrivenListMemo: Selector<BallotWithPollAndOrg[]> = makeMemo(
  composeXorResults(selectBallotPrimitiveHashFn, selectPollPrimitiveHashFn, selectCompaniesPrimitiveHashFn),
  selectBallotDrivenList
)
// Identical signature to selectCompositeList, just memoizes result for performance reasons
const selectCompositeListMemo: Selector<BallotWithPollAndOrg[]> = makeMemo(
  composeXorResults(
    selectBallotPrimitiveHashFn,
    selectPollPrimitiveHashFn,
    selectCompaniesPrimitiveHashFn,
    selectBreakdownPrimitiveHashFn
  ),
  selectCompositeList
)

// : BallotWithPollAndOrg[]
// ballots |> Set.union (polls |> Set.except ballots) |> Set.toList

const composeAnd =
  <T>(...fns: ((x: T) => boolean)[]) =>
  (x: T) =>
    fns.reduce((acc, item) => acc && item(x), true)

const fixedFilters = {
  isInOrganization: (organizationId: string) => (p: BallotWithPollAndOrg) =>
    p.poll.relationships.generalMeeting.organizationId === organizationId,
  isInMyPortfolio: (state: RootState) => (p: BallotWithPollAndOrg) =>
    pipe(state, selectOrganizationBreakdownSet, (orgBreakdown) => orgBreakdown?.has(p.organization.id)),
  isOpen: (p: BallotWithPollAndOrg) => isPollOpen(p.poll),
  isClosed: (p: BallotWithPollAndOrg) => !isPollOpen(p.poll),
  hasNoResponse: (poll: BallotWithPollAndOrg) => poll.ballot?.investorVote == null,
  hasResponse: (poll: BallotWithPollAndOrg) => poll.ballot?.investorVote?.response != null,
  hasOutcome: (poll: BallotWithPollAndOrg) => poll.poll.relationships.proposal.outcome != null,
  hasNotOutcome: (p: BallotWithPollAndOrg) => p.poll.relationships.proposal.outcome == null,
  isAgmInTheFuture: (p: BallotWithPollAndOrg) =>
    isAfter(timestampToDate(p.poll.relationships.generalMeeting.date), new Date()),
  hasVotingOptions: (p: BallotWithPollAndOrg) =>
    p.poll.relationships.proposal.votingOptions !== undefined &&
    p.poll.relationships.proposal.votingOptions &&
    p.poll.relationships.proposal.votingOptions.length > 0,
}

export const filter = {
  fixed: fixedFilters,
  dynamic: {
    outcomePreFilter: (filters: SearchFilter) => (poll: BallotWithPollAndOrg) =>
      filters.binaryFilters.awaitingResults // rename to outcome
        ? !fixedFilters.hasOutcome(poll) && !fixedFilters.isOpen(poll)
        : true, // filter not set, bypass
    myVotesPreFilter: (filters: SearchFilter) => (poll: BallotWithPollAndOrg) =>
      filters.binaryFilters.myVotes ? fixedFilters.hasResponse(poll) : fixedFilters.hasNoResponse(poll), // filter inverted
    filterByVoteTags: (filters: SearchFilter) => {
      const tagIds = Object.entries(filters.pollTags).reduce(
        (prev, [tag, enabled]) => (enabled ? prev.add(tag) : prev),
        new Set<string>()
      )

      const mapPollTagIdsToKeys = Object.entries(PollTag).reduce(
        (acc, [key, value]) => acc.set(value, key),
        new Map() as Map<PollTag, string>
      )

      if (tagIds.size > 0)
        return ({ poll }: BallotWithPollAndOrg) =>
          poll.tags.find((t) => tagIds.has(mapPollTagIdsToKeys.get(t.id as any)!)) != null

      return () => true
    },
    filterByOrganizationIndustry: (filters: SearchFilter) => {
      if (Object.values(filters.votingOrganizationIndustries).includes(true)) {
        const industries = Object.entries(filters.votingOrganizationIndustries).reduce(
          (prev, [category, active]) => (active ? prev.add(category) : prev),
          new Set<string>()
        )

        return (poll: BallotWithPollAndOrg) =>
          (poll.organization.industryCategory && industries.has(poll.organization.industryCategory)) || false
      }
      return () => true
    },
  },
}

export const sort = {
  byMostRecentAGMDate: (a: BallotWithPollAndOrg, b: BallotWithPollAndOrg) =>
    timestampToDate(b.poll.relationships.generalMeeting.date).getTime() -
    timestampToDate(a.poll.relationships.generalMeeting.date).getTime(),
  byTimeRemaining: (a: BallotWithPollAndOrg, b: BallotWithPollAndOrg) =>
    timestampToDate(a.poll.endDate).getTime() - timestampToDate(b.poll.endDate).getTime(),
  byAGMDateAsc: (a: BallotWithPollAndOrg, b: BallotWithPollAndOrg) =>
    timestampToDate(a.poll.relationships.generalMeeting.date).getTime() -
    timestampToDate(b.poll.relationships.generalMeeting.date).getTime(),
}

const TagRecord = Record<Tag>({ id: '', title: '' }) // we need structural comparison for this type
export const consolidateAndCountTags = (tags: Tag[]) =>
  tags
    .reduce((acc, item) => {
      // count no of occurances of each tag
      const tag = TagRecord(item)
      const num = acc.get(tag) ?? 0
      return acc.set(tag, num + 1)
    }, ImmMap<Readonly<Tag>, number>())
    .toArray()
    .map(([tag, count]) => ({ tag, count }))
    .sort((a, b) => b.count - a.count) // sort by count descending

export const selectUserMostPopularTag = (userVoteCountThreshold: number) =>
  compose(
    selectInvestorStatistics,
    Payload.map((stats) => {
      if (stats.pollTagsVoteCount[0] == null) return undefined

      if (stats.pollTagsVoteCount[0].voteCount < userVoteCountThreshold) return undefined

      return stats.pollTagsVoteCount[0]
    })
  )

export const listOpenVotesFilteredBySearch = errorPendingOrData((state: RootState) => {
  return selectBallotDrivenListMemo(state)
    .filter(
      composeAnd(
        filter.fixed.isOpen,
        filter.fixed.hasVotingOptions,
        filter.dynamic.myVotesPreFilter(state.searchFilters),
        filter.dynamic.outcomePreFilter(state.searchFilters),
        filter.dynamic.filterByOrganizationIndustry(state.searchFilters),
        filter.dynamic.filterByVoteTags(state.searchFilters)
      )
    )
    .sort(sort.byTimeRemaining)
})

export const listVotesAwaitingResultFilteredBySearch = errorPendingOrData((state: RootState) =>
  selectBallotDrivenListMemo(state)
    .filter(
      composeAnd(
        filter.fixed.hasNotOutcome,
        filter.fixed.isClosed,
        filter.fixed.isAgmInTheFuture,
        filter.dynamic.myVotesPreFilter(state.searchFilters),
        filter.dynamic.outcomePreFilter(state.searchFilters),
        filter.dynamic.filterByOrganizationIndustry(state.searchFilters),
        filter.dynamic.filterByVoteTags(state.searchFilters)
      )
    )
    .sort(sort.byAGMDateAsc)
)

export const listOpenForOrganizationWithoutResponses = (organizationId: string) =>
  errorPendingOrData((state: RootState) =>
    selectBallotDrivenListMemo(state).filter(
      composeAnd(
        filter.fixed.isInOrganization(organizationId),
        filter.fixed.isOpen,
        filter.fixed.hasNoResponse,
        filter.fixed.hasVotingOptions
      )
    )
  )

export const listPollResultsForOrganization = (organizationId: string) =>
  errorPendingOrData((state: RootState) =>
    selectCompositeListMemo(state)
      .filter(composeAnd(filter.fixed.isClosed, filter.fixed.hasOutcome, filter.fixed.isInOrganization(organizationId)))
      .sort(sort.byMostRecentAGMDate)
  )
