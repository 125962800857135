/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore as toolkitConfigureStore, Action, ThunkAction } from '@reduxjs/toolkit'
// eslint-disable-next-line no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { Config } from '../config'
import { Services } from './services'
import rootReducer, { RootState } from './rootReducer'

const configureStore = (extraArgument: { services: Services }, config: Config) => {
  return toolkitConfigureStore({
    reducer: rootReducer,
    preloadedState: { config: { config } },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({ thunk: { extraArgument } })
    },
  })
}

export type AppThunk = ThunkAction<void, RootState, { services: Services }, Action<string>>

type AppDispatch = ReturnType<typeof configureStore>['dispatch']
type ThinkDispatch = (action: AppThunk) => void

export const useAppDispatch: () => (arg: Parameters<ThinkDispatch>[0] | Parameters<AppDispatch>[0]) => void =
  useDispatch as any
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export { configureStore }
