import React, { FC, PropsWithChildren, createContext, useContext } from 'react'
import { LoggerService } from './Logger'
import { LoggerServiceConsole } from './LoggerConsole'

export const LoggerContext = createContext<LoggerService>(LoggerServiceConsole)

interface LoggerProviderProps {
  logger: LoggerService
}

export const LoggerProvider: FC<PropsWithChildren<LoggerProviderProps>> = ({ logger, children }) => {
  return <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>
}

export const useLogger = () => useContext(LoggerContext)
