import { OrganizationBreakdown, ModelPortfolioId, GM, Organization, isPollOpen } from '@tumelo/shared'
import { LoggerService } from '@tumelo/logging'
import * as accountActions from './features/account'
import * as breakdownActions from './features/breakdown'
import * as organizationsActions from './features/organizations'
import * as ballotsActions from './features/ballots'
import * as pollsActions from './features/polls'
import * as accountCompositionActions from './features/accountComposition'
import * as generalMeetingsActions from './features/generalMeetings'
import { AppThunk } from './store'
import { create as createCompositionWithInstruments } from './types/CompositionWithInstruments'
import { isValueState, Payload } from './payload'
import { logOut } from './features/auth/asyncActions'

// a safe error boundary that turns partial functions into functions by
// catching thrown exceptions, and wrapping them in a Payload<T> error
export const tryExecute =
  (logger: LoggerService) =>
  async <T>(tryExFn: () => Promise<T>): Promise<Payload<T>> => {
    try {
      return await tryExFn()
    } catch (ex) {
      logger.logError(ex)
      return 'error'
    }
  }

export const fetchOrganizationBreakdown =
  (): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { loggerService, accountService } = services
    const tryExecuteL = tryExecute(loggerService)
    dispatch(breakdownActions.setOrganizationBreakdown('pending'))

    const breakdown = await tryExecuteL(async (): Promise<OrganizationBreakdown> => {
      const { account } = getState().account
      if (!isValueState(account) || account === 'not-configured') {
        throw new Error('user account not found and not configured when getting breakdown')
      }
      const { config } = getState().config
      if (!isValueState(config)) {
        throw new Error('config not found when getting breakdown')
      }
      const breakdown = await accountService.getAccountOrganizationEquityBreakdown(account)
      if (!breakdown) {
        throw new Error('No organization breakdown found')
      }
      return breakdown
    })

    if (isValueState(breakdown)) {
      dispatch(organizationsActions.addMany(breakdown.organizationEntries.map(({ organization }) => organization)))
      dispatch(breakdownActions.setOrganizationBreakdown(breakdown))
    } else {
      dispatch(breakdownActions.setOrganizationBreakdown(breakdown))
    }
  }

export const fetchPollsAndBallots =
  (partial = false): AppThunk =>
  async (dispatch, getState, { services }) => {
    dispatch(ballotsActions.setPending())
    dispatch(pollsActions.setPending())
    const { pollService, votingService, loggerService, organizationService } = services

    try {
      if (!isValueState(getState().breakdown)) {
        throw new Error('attempting to fetch polls/ballots before having fetched the breakdown')
      }
      const listPolls = partial ? pollService.fetchRecentPolls() : pollService.listAllPolls()
      const [{ polls, organizations: orgsFromPolls }, ballots] = await Promise.all([
        listPolls,
        votingService.listInvestorBallots(),
      ])

      const allPollOrgIds = new Set(polls.map((poll) => poll.relationships.generalMeeting.organizationId))
      const orgIdsNotInStore = new Set(
        Array.from(allPollOrgIds).filter(
          (id) =>
            !organizationsActions.organizationAdapter
              .getSelectors()
              .selectById(getState().organizations.organizations, id)
        )
      )
      // Check if any polls don't have voting options that are open and raise a sentry error
      const pollsWithoutVotingOptions = polls.filter(
        (poll) =>
          (!poll.relationships.proposal.votingOptions || poll.relationships.proposal.votingOptions?.length === 0) &&
          isPollOpen(poll)
      )
      if (pollsWithoutVotingOptions.length > 0) {
        loggerService.logError(
          new Error(
            `proposals without voting option discovered, proposal ids: ${pollsWithoutVotingOptions.map(
              (poll) => poll.relationships.proposal.id
            )}`
          )
        )
      }
      // get additional orgs if they are missing
      if (Array.from(orgIdsNotInStore).length > 0) {
        const idsInPolls = new Set<string>(orgsFromPolls?.map(({ id }) => id) ?? [])
        const idsNotInPolls = new Set<string>(Array.from(orgIdsNotInStore).filter((id) => !idsInPolls.has(id)))
        const extraOrgs = await organizationService.batchGetOrganizations(Array.from(idsNotInPolls.values()))
        const orgs: Organization[] = [...extraOrgs, ...(orgsFromPolls ?? [])]
        dispatch(organizationsActions.addMany(orgs))
      }
      dispatch(ballotsActions.setBallots(ballots))
      // Set the status of polls to either all or partial
      dispatch(partial ? pollsActions.setPartial() : pollsActions.setStatusAll())
      dispatch(pollsActions.setPolls(polls))
    } catch (e) {
      loggerService.logError(e)
      dispatch(ballotsActions.setError())
      dispatch(pollsActions.setError())
    }
  }

export const updateAccountModelPortfolio =
  (modelPortfolioId: ModelPortfolioId): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { dashboardBffService, instrumentService, loggerService } = services
    await tryExecute(loggerService)(async () => {
      const { account } = getState().account
      if (!isValueState(account) || account === 'not-configured') {
        throw new Error('account must be set before can update')
      }
      dispatch(accountActions.setAccount('pending'))
      const { account: accountAfterUpdate, composition } =
        await dashboardBffService.CreateCompositionWithModelPortfolio(modelPortfolioId)
      dispatch(accountActions.setAccount(accountAfterUpdate))
      dispatch(breakdownActions.setOrganizationBreakdown('not-initialised'))

      dispatch(accountCompositionActions.setAccountCompositionWithInstrumentsResult('pending'))
      const compositionInstrumentsResult = await instrumentService.createCompositionInstruments(composition)
      if (compositionInstrumentsResult.missingInstrumentReferences.length !== 0) {
        loggerService.logError(
          `Instrument in account composition missing from habitat subscribed instruments ${compositionInstrumentsResult.missingInstrumentReferences}.`
        )
      }
      const updatedComposition = compositionInstrumentsResult.composition
      const compositionWithInstruments = createCompositionWithInstruments(
        compositionInstrumentsResult.instrumentMap,
        updatedComposition
      )
      dispatch(accountCompositionActions.setAccountCompositionWithInstrumentsResult(compositionWithInstruments))
    })
  }

export const createAccount =
  (modelPortfolioId: ModelPortfolioId): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { accountService, loggerService } = services
    const tryExecuteL = tryExecute(loggerService)
    dispatch(accountActions.setAccount('pending'))
    const account = await tryExecuteL(() => accountService.createAccountWithPortfolioId(modelPortfolioId))
    dispatch(accountActions.setAccount(account))
  }

export const fetchGeneralMeeting =
  (organizationId: string): AppThunk =>
  async (dispatch, _, { services }) => {
    const { organizationService, loggerService } = services
    const tryExecuteL = tryExecute(loggerService)
    dispatch(generalMeetingsActions.set('pending'))
    const generalMeetings = await tryExecuteL(() => organizationService.listGeneralMeetings(organizationId))
    dispatch(generalMeetingsActions.set({ organizationId, generalMeetings: generalMeetings as GM[] }))
  }

export const deleteAccount =
  (redirect?: () => void): AppThunk =>
  async (dispatch, _, { services }) => {
    try {
      await services.investorExtendedProfileService.deleteProfile()
      await services.investorService.deleteInvestor()
      dispatch(
        logOut(
          () => {
            if (redirect) {
              redirect()
            }
          },
          { global: true }
        )
      )
    } catch (e) {
      services.loggerService.logError(e)
    }
  }
