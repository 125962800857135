import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
export const Collapse = ({ in: isExpanded, id, children }) => {
    const contentRef = useRef(null);
    const [contentHeight, setContentHeight] = useState(0);
    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [isExpanded]);
    return (React.createElement(CollapseWrapper, { id: id, role: "region", "aria-labelledby": id, "$isExpanded": isExpanded, "$contentHeight": contentHeight, ref: contentRef }, children));
};
const CollapseWrapper = styled.div `
  height: ${({ $isExpanded, $contentHeight }) => ($isExpanded ? `${$contentHeight}px` : '0px')};
  overflow: hidden;
  transition: height 0.3s ease;
`;
