import React, { useEffect } from 'react'
import { useLogger } from '@tumelo/logging'
import { isValueState } from '../../application/payload'
import { selectInvestorSub } from '../../application/features/investor/investorSelector'
import { useAppSelector } from '../../application/store'

export const SetUser: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const sub = useAppSelector(selectInvestorSub)

  const logger = useLogger()

  useEffect(() => {
    if (isValueState(sub)) {
      logger.setUserData({ id: sub })
    }
  }, [logger, sub])

  return <>{children}</>
}
