import React from 'react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { StylesProvider } from '@mui/styles'
// eslint-disable-next-line no-restricted-imports
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { useAsync } from 'react-use'
import { useCookies } from 'react-cookie'
import { cookieConsent, CookieConsentValue } from '@tumelo/shared'
import { createAnalyticsMixpanel, AnalyticsConsoleLogger, combineAnalytics } from '@tumelo/analytics'
import { GlobalStyle } from '@tumelo/designsystem'
import {
  LoggerService,
  LoggerServiceConsole,
  LoggerServiceSentry,
  LoggerServiceCompound,
  LoggerProvider,
} from '@tumelo/logging'
import { getNewConfig } from '../config'
import { SoftConfigThemeSchema } from '../config/SoftConfigTheme'
import { GenericError } from '../components/Error'
import { configureStore } from '../application/store'
import { DebugWrapper } from '../utils/config/DebugUtils'
import { ScrollToTop } from '../utils/ScrollToTop'
import { IdleTimeoutWrapper } from '../components/IdleTimeout/IdleTimeoutWrapper'
import { BrowserWarning } from '../components/BrowserWarning/BrowserWarning'
import { CookieBanner } from '../components/CookieBanner'
import { SSOTermsAcceptanceModalConnected } from '../containers/SSOTermsAcceptanceModalConnected'
import { DashboardRoutes } from '../routes'
import { returnServices } from '../application/services'
import * as Analytics from '../application/analytics'
import { configure as AuthConfigure, AuthWrapper } from '../auth'
import { getQueryVariable } from '../utils/getQueryVariable'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { PlannedMaintenance } from '../components/PlannedMaintenance'
import { SetUser } from '../utils/setUserData'

// Unregister all service workers - required to ensure we don't have old service workers hanging around.
// Added after removing service worker from the codebase.
// This should remain until we're confident all old service workers are gone.
const serviceWorkerUnregister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister()
      }
    })
  }
}

export const App: React.FC = () => {
  const isProduction = process.env.NODE_ENV === 'production'
  // TODO Probably needs rethinking in future with configuration.
  const isProductionPlatformUrl = window.location.hostname.endsWith('platform.tumelo.com')

  const [cookies] = useCookies([cookieConsent])
  const cookiesAccepted = cookies[cookieConsent] === CookieConsentValue.Accepted

  const sentryEnvironment = window.location.hostname.endsWith('.platform.tumelo.com') ? 'production' : 'development'
  const includeSentryLogger = isProduction && isProductionPlatformUrl && cookiesAccepted
  const logger = setupLoggingService(sentryEnvironment, includeSentryLogger)

  // Allows switching of config/data files on previews / in dev
  const pathPrefix = isProductionPlatformUrl ? '' : (getQueryVariable('config') ?? '')
  const state = useAsync(getNewConfig(pathPrefix))

  // Unregister service worker
  React.useEffect(() => {
    serviceWorkerUnregister()
  }, [])

  if (state.loading) {
    return null
  }

  if (state.error || !state.value) {
    logger.logError(state.error)
    return (
      <ThemeProvider theme={SoftConfigThemeSchema.parse()}>
        <GenericError onClick={() => window.location.reload()} />
        <GlobalStyle />
      </ThemeProvider>
    )
  }

  const config = state.value
  // configure auth
  AuthConfigure(config.aws)

  // configure Analytics
  const analyticsEngines = []
  if (cookiesAccepted) {
    if (config.mixpanel.token && config.mixpanel.token !== '')
      analyticsEngines.push(createAnalyticsMixpanel(config.mixpanel.token, { api_host: config.mixpanel.apiHost }))
  }

  if (!isProduction) analyticsEngines.push(AnalyticsConsoleLogger)
  const analytics = combineAnalytics(...analyticsEngines)

  const services = returnServices(config.services, logger, analytics, { fetchPathPrefix: pathPrefix })
  const store = configureStore({ services }, config)

  const queryParameters = new URLSearchParams(window.location.search)
  const bypass = queryParameters.get('bypass')

  if (config.plannedMaintenance === true && bypass === null) {
    return <PlannedMaintenance />
  }
  return (
    <LoggerProvider logger={logger}>
      <Analytics.AnalyticsContext.Provider value={analytics}>
        <Provider store={store}>
          <StylesProvider injectFirst>
            <DebugWrapper enable={config.enableConfigWidget ?? false}>
              {(theme) => (
                <ThemeProvider theme={theme}>
                  <Router>
                    <ErrorBoundary>
                      <Analytics.PageViewFromLocationPathname>
                        <Analytics.SetUserProperties>
                          <Analytics.TrackLoadPWA>
                            <SetUser>
                              <ScrollToTop />
                              <Routes>
                                <Route
                                  path="/*"
                                  element={
                                    <>
                                      <BrowserWarning />
                                      <CookieBanner>
                                        <AuthWrapper>
                                          <IdleTimeoutWrapper>
                                            <SSOTermsAcceptanceModalConnected>
                                              <DashboardRoutes />
                                            </SSOTermsAcceptanceModalConnected>
                                          </IdleTimeoutWrapper>
                                        </AuthWrapper>
                                      </CookieBanner>
                                    </>
                                  }
                                />
                              </Routes>
                            </SetUser>
                          </Analytics.TrackLoadPWA>
                        </Analytics.SetUserProperties>
                      </Analytics.PageViewFromLocationPathname>
                    </ErrorBoundary>
                  </Router>
                  <GlobalStyle />
                </ThemeProvider>
              )}
            </DebugWrapper>
          </StylesProvider>
        </Provider>
      </Analytics.AnalyticsContext.Provider>
    </LoggerProvider>
  )
}

const NextApp: React.FC = () => {
  const [isMounted, setIsMounted] = React.useState(false)

  React.useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return null
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Tumelo Platform</title>
      </Head>
      <App />
    </>
  )
}

export default dynamic(() => Promise.resolve(NextApp), {
  ssr: false,
})

const setupLoggingService = (environment: string, includeSentryLogger: boolean): LoggerService => {
  if (includeSentryLogger) {
    const dsn = 'https://93602412781842aebcbea429efba42ef@sentry.io/5127805'
    const release = process.env.NEXT_PUBLIC_APP_RELEASE // in CI will contain the COMMIT_SHA

    const ignoreErrors = [
      'ResizeObserver loop limit exceeded',
      // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
      'TypeError: Cancelled',
      // Issue impacting WebView (most often browsers embedded in apps like email clients) - no obvious fix
      "Can't find variable: bridge",
      'ReferenceError: bridge is not defined',
      // https://stackoverflow.com/questions/69261499/what-is-instantsearchsdkjsbridgeclearhighlight
      "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
      // https://sentry.io/answers/load-failed-javascript/ - impacts safari but doesn't seem to cause actual issues to an end user. It provides more noise than value.
      'TypeError: Load failed',
    ]
    const denyUrls = [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ]

    const tracesSampleRate = 0.2

    const init = release
      ? { dsn, release, environment, denyUrls, ignoreErrors, tracesSampleRate }
      : { dsn, environment, denyUrls, ignoreErrors, tracesSampleRate }

    const sentryLogger = new LoggerServiceSentry(init)

    return new LoggerServiceCompound(LoggerServiceConsole, sentryLogger)
  }

  return new LoggerServiceCompound(LoggerServiceConsole)
}
