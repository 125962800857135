import {
  Ballot,
  VoteMethod,
  BallotId,
  dateToTimestamp,
  InvestorVoteResponse,
  PollId,
  ProposalId,
  VotingPolicyId,
} from '@tumelo/shared'
import { ServiceInjectedFetch } from '../ServiceInjectedFetch'
import {
  VotingRecommendation,
  VotingRecommendationId,
  VotingRecommendations,
} from '../../features/votingPolicies/types'
import { VotingService } from './VotingService'
import { schema } from './types'

export class VotingServiceInjectedFetch extends ServiceInjectedFetch implements VotingService {
  private ballots: Map<string, Ballot> | undefined

  private ballotsByPollId: Map<PollId, Ballot> | undefined

  async castVote(ballotId: BallotId, response: InvestorVoteResponse): Promise<Ballot> {
    if (this.ballots === undefined) {
      this.ballots = await this.fetchBallots()
    }
    const ballot = this.ballots.get(ballotId)
    if (ballot === undefined) {
      throw new Error(`Ballot for id ${ballotId} not found`)
    }
    const newBallot: Ballot = {
      ...ballot,
      investorVote: { response, responseTime: dateToTimestamp(new Date()), voteMethod: VoteMethod.Manual },
    }
    this.ballots.set(ballotId, { ...newBallot })
    return newBallot
  }

  async sendComment(ballotId: BallotId, comment: string): Promise<Ballot> {
    if (this.ballots === undefined) {
      this.ballots = await this.fetchBallots()
    }
    const ballot = this.ballots.get(ballotId)
    if (ballot === undefined) {
      throw new Error(`Ballot for id ${ballotId} not found`)
    }
    const newBallot: Ballot = { ...ballot, comment: { content: comment, createTime: dateToTimestamp(new Date()) } }
    this.ballots.set(ballotId, { ...newBallot })
    return newBallot
  }

  async listInvestorBallots(hasVoted = false) {
    if (this.ballots === undefined) {
      this.ballots = await this.fetchBallots()
    }
    if (hasVoted) {
      return Array.from(this.ballots.values()).filter((ballot) => Boolean(ballot.investorVote))
    }
    return Array.from(this.ballots.values())
  }

  async getInvestorBallotByBallotId(ballotId: BallotId) {
    if (this.ballots === undefined) {
      this.ballots = await this.fetchBallots()
    }
    return this.ballots.get(ballotId)
  }

  async getInvestorBallotByPollId(pollId: PollId) {
    if (this.ballots === undefined) {
      this.ballots = await this.fetchBallots()
    }
    if (this.ballotsByPollId === undefined) {
      this.ballotsByPollId = new Map<PollId, Ballot>(Array.from(this.ballots.values()).map((b) => [b.pollId, b]))
    }
    return this.ballotsByPollId.get(pollId)
  }

  async listVotingRecommendationForProposalId(
    _votingPolicyId: VotingPolicyId,
    proposalId: ProposalId
  ): Promise<VotingRecommendation | 'not-configured'> {
    const data = await this.awaitableJsonFetch()
    const importedVotingService = schema.validateSync(data)
    if (!importedVotingService.votingRecommendations) {
      return 'not-configured'
    }
    const votingRecommendationsMap: VotingRecommendations = importedVotingService.votingRecommendations.map((vR) => ({
      id: vR.id as VotingRecommendationId,
      votingPolicyId: vR.votingPolicyId as VotingPolicyId,
      organizationId: vR.organizationId,
      generalMeetingId: vR.generalMeetingId,
      proposalId: vR.proposalId as ProposalId,
      recommendation: {
        instruction: vR.recommendation.instruction as InvestorVoteResponse,
        rationale: vR.recommendation.rationale as string,
      },
      createTime: dateToTimestamp(vR.createTime),
      updateTime: dateToTimestamp(vR.updateTime),
    }))
    const votingRecommendation = votingRecommendationsMap.find((vR: { proposalId: ProposalId }) => {
      return vR.proposalId === proposalId
    })
    if (votingRecommendation === undefined) return 'not-configured'
    return votingRecommendation
  }

  private async fetchBallots(): Promise<Map<string, Ballot>> {
    const data = await this.awaitableJsonFetch()
    const importedVotingService = schema.validateSync(data)
    if (!importedVotingService.ballots) {
      return new Map()
    }
    return importedVotingService.ballots
      .map((b) => ({
        id: b.id as BallotId,
        pollId: b.pollId as PollId,
        createTime: b.createTime,
        expirationTime: b.expirationTime,
        investorVote: b.investorVote
          ? {
              response: b.investorVote.response,
              responseTime: dateToTimestamp(b.investorVote.responseTime),
            }
          : undefined,
      }))
      .reduce((acc, ballot) => acc.set(ballot.id, ballot), new Map())
  }
}
