import * as yup from 'yup'
import { naicsCodeToIndustryCategory, Organization, OrganizationBio, stringToNAICSCode } from '@tumelo/shared'

const organizationBioSchema = yup
  .object({
    description: yup.string().required(),
    sourceUrl: yup.string().notRequired(),
    source: yup.string().required(),
  })
  .required()

export const organizationSchema = yup
  .object({
    id: yup.string().required(),
    externalIdentifiers: yup.array(yup.string().required()).notRequired(),
    legalName: yup.string().required(),
    displayName: yup.string().notRequired(),
    websiteUrl: yup.string().notRequired(),
    logoUrl: yup.string().notRequired(),
    bio: organizationBioSchema.notRequired().default(undefined),
    naics2017: yup.string().notRequired(),
  })
  .required()

export const mapOrganization = (organization: yup.InferType<typeof organizationSchema>): Organization => {
  const { id, websiteUrl, logoUrl, bio, naics2017 } = organization
  return {
    id,
    title: organization.displayName ? organization.displayName : organization.legalName,
    websiteUrl: websiteUrl ?? undefined,
    logoUrl: logoUrl ?? undefined,
    bio: mapOrganizationBio(bio ?? undefined),
    industryCategory: naicsCodeToIndustryCategory(stringToNAICSCode(naics2017 ?? undefined)),
  }
}

const mapOrganizationBio = (
  bio: yup.InferType<typeof organizationBioSchema> | undefined
): OrganizationBio | undefined => {
  if (bio === undefined) {
    return undefined
  }
  const { sourceUrl, source, description } = bio
  switch (source) {
    case 'wikipedia':
      return { description, sourceUrl: sourceUrl ?? undefined, source: 'Wikipedia' }
    case 'tumelo':
      return { description, sourceUrl: sourceUrl ?? undefined, source: 'Tumelo' }
    default:
      throw new Error(`could not map source ${source}`)
  }
}
